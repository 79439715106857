import { useParams } from "react-router-dom";
//import { BrowserRouter } from "react-router-dom";
import styles from "./styles.module.css";

export const YoFrame = () => {
    const {id} = useParams();

    return (
        <div className={styles.ifram}>               
            <iframe 
            src = {"https://yoitsme.app/" + id}
            className={styles.box}
            frameBorder='0'
            
            />       
        </div>
        
    );
}
