
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Route, Switch, } from 'react-router-dom';
import { Iframe } from "./Iframe";
import { YoFrame } from './YoFrame';

function App() { 
  const location = useLocation();
  const slug = location.pathname.substring(1);  
  var array = ['home', 'privacy', 'terms', 'shop', 'explore', 'trending', 'search', 'login', 'signup']; //, length = array.length;
  let i = 0;
  let arrayLength = array.length;
 
  while(i < arrayLength){
    let val = array[i]; 
    if(val == slug){
      console.log('var = slug');
      return (
        <Route path="/:id" component={YoFrame} />
      );
    }
    i++;        
  }
  
    return (
      <Switch>
        <Route path='/:id' component={Iframe} />
        <Route path='/' component={() => {
          window.location.href = "https://yoitsme.app";
          return null;
        }} />
      </Switch>
    );
  }
export default App;
