
//import { Route, Switch, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
//import { BrowserRouter } from "react-router-dom";
import styles from "./styles.module.css";

export const Iframe = () => {

const {id} = useParams();
let isNum = /^\d+$/.test(id);

if(isNum === false){
    return (
        <div className={styles.ifram}>               
            <iframe 
            src = {"https://yoitsme.app/yo/" + id}
            className={styles.box}
            frameBorder='0'
            
            />       
        </div>
        
    );
    
    
} else {
  
    window.history.replaceState("Updated URL", 'Verified Page', 'Verified');

    return (
        <div className={styles.ifram}>               
         <iframe 
            src = {"https://yoitsme.app/v/" + id }
            className={styles.box}
            frameBorder='0'
           
         />       
        </div>
       
    );
    
}

}



